<div class="mb-3">
  <div class="form-group col-12">
    <div class="row">
      <div class="col-6" [innerHTML]="translationService.getTranslation('period')">
      </div>
      <div class="col-6">
        {{ subscription.validDate.validFrom | date }} -
        {{ (subscription.validDate.validUntil) ? (subscription.validDate.validUntil | date) : translationService.getTranslation('subscription_endless') }}
      </div>
    </div>
  </div>
  <div class="form-group col-12" *ngIf="subscription.commitmentDate && subscription.commitmentDate > today">
    <div class="row">
      <div class="col-6" [innerHTML]="translationService.getTranslation('commitmentDate')">
      </div>
      <div class="col-6">
        {{ subscription.commitmentDate | date}}
      </div>
    </div>
  </div>
  <div class="form-group col-12">
    <div class="row">
      <div class="col-6" [innerHTML]="translationService.getTranslation('order_product')">
      </div>
      <div class="col-6">
        {{ subscription.productDescription }}
      </div>
    </div>
  </div>
  <ng-container *ngIf="subscription.variantDescription">
    <div class="form-group col-12">
      <div class="row">
        <div class="col-6" [innerHTML]="translationService.getTranslation('order_edition')">
        </div>
        <div class="col-6">
          {{ subscription.variantDescription }}
        </div>
      </div>
    </div>
  </ng-container>
</div>
