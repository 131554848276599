<div class="row">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('invoices')"></h4>
  </div>
  <div class="col-12 mb-5" [innerHTML]="translationService.getTranslation('invoices_description')">
  </div>
</div>

<div class="row">
  <div class="col-12">
    <mat-paginator class="float-start mb-3" [pageSize]="pageSize" [length]="totalAmount" [disabled]="isLoading"
                   [hidePageSize]="true" [showFirstLastButtons]="false" (page)="loadInvoices($event)">
    </mat-paginator>
  </div>
</div>

<div id="invoice-page">
  <div class="row" *ngIf="!isLoading">
    <div [className]="
    'col-' + (CssPropertiesEnum.customerFxFlex|cssProperty: vHostService) + ' ' +
    'col-sm-' + (CssPropertiesEnum.customerFxFlexSM|cssProperty: vHostService) + ' ' +
    'col-md-' + (CssPropertiesEnum.customerFxFlexMD|cssProperty: vHostService) + ' ' +
    'col-lg-' + (CssPropertiesEnum.customerFxFlexLG|cssProperty: vHostService) + ' ' +
    'col-xl-' + (CssPropertiesEnum.customerFxFlexXL|cssProperty: vHostService) + ' ' +
    'col-xxl-' + (CssPropertiesEnum.customerFxFlexXXL|cssProperty: vHostService) + ' mb-4'" *ngFor="let invoice of currentInvoices">
      <mat-card appearance="outlined" class="mat-elevation-z4 pointer h-100">
        <mat-card-header class="mb-3">
          <mat-card-title
            [innerHTML]="translationService.getTranslation(invoice.credit ? 'invoice_creditOf' : 'invoice_of') + ( invoice.invoiceDate | date)">
          </mat-card-title>
          <mat-card-subtitle
            [innerHTML]="translationService.getTranslation('invoice_number') + ': ' + invoice.invoiceNo">
          </mat-card-subtitle>
          <mat-card-subtitle
            [innerHTML]="translationService.getTranslation(invoice.credit ? 'invoice_creditAmount' : 'invoice_invoiceAmount') + ': ' + ( invoice.invoiceAmount | currency )">
          </mat-card-subtitle>
        </mat-card-header>
        <ng-container *ngIf="invoice.hasDocument">
          <embed [src]="invoice.invoiceDocumentUrl | safeResource" type="application/pdf">
        </ng-container>
      </mat-card>
    </div>
  </div>
</div>
