<div class="row">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('sub_change_intro')"></h4>
  </div>
  <div class="col-12" [innerHTML]="translationService.getTranslation('sub_change_description')">
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div *ngIf="isLoadingSub" style="text-align: center;">
      <app-loading-spinner></app-loading-spinner>
    </div>
    <app-general-errors [errors]="errorsArr"></app-general-errors>
    <div class="mt-3" *ngIf="!isLoadingSub && subs.length == 0 && !foundDigitalSubscription" [innerHTML]="translationService.getTranslation('intro_noSubs')"></div>
    <div class="mt-3" *ngIf="!isLoadingSub && subs.length == 0 && foundDigitalSubscription" [innerHTML]="translationService.getTranslation('intro_noSubs_onlyDigital')"></div>
    <ng-container *ngIf="!isLoadingSub && subs.length > 0">
      <mat-card appearance="outlined" *ngFor="let sub of subs" class="mt-3">
        <div class="row m-3">
          <div class="col-12 col-md-6">
            <mat-card-title>{{sub.productDescription}}</mat-card-title>
            <mat-card-subtitle *ngIf="sub.subId" [innerHTML]="translationService.getTranslation('order_id') + ': ' + sub.subId">
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="sub.variantDescription" [innerHTML]="translationService.getTranslation('order_edition') + ': ' + sub.variantDescription">
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="sub.validDate" [innerHTML]="translationService.getTranslation('period') + ': ' +
              (sub.validDate.validFrom | date) + ' - ' +
              ((sub.validDate.validUntil) ? (sub.validDate.validUntil | date) : translationService.getTranslation('subscription_endless'))">
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="sub.curDelivery">
              {{sub.curDelivery.person.addressCodeDescription}}
              {{sub.curDelivery.person.firstname}}
              {{sub.curDelivery.person.lastname}},
              {{sub.curDelivery.address.city}},
              {{sub.curDelivery.address.street}} {{sub.curDelivery.address.houseno | houseNoPipe}}
            </mat-card-subtitle>
          </div>
          <div class="col-12 col-md-6">
            <mat-card-actions class="card-actions">
              <button mat-raised-button color="primary" (click)="onNewSubChange(sub)" [innerHTML]="translationService.getTranslation('button_new_sub_change')"></button>
            </mat-card-actions>
          </div>
        </div>

        <mat-card-content *ngIf="sub && sub.subChanges && sub.subChanges.length > 0">
          <mat-accordion multi>
            <mat-expansion-panel *ngFor="let subChange of sub.subChanges; let innerI = index">
              <mat-expansion-panel-header *ngIf="subChange.state != 3">
                <mat-panel-title [innerHTML]="translationService.getTranslation('label_' + subChange.changeType.toString() + '_description')"></mat-panel-title>
                <mat-panel-description>
                  {{subChange.validDate.validFrom | date}}
                  {{(subChange.validDate.validUntil && subChange.validDate.validUntil != subChange.validDate.validFrom) ?
                  ' - ' + (subChange.validDate.validUntil | date) : ''
                  }}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-action-row *ngIf="subChange.state != 3">
                <button *ngIf="!subChange.outdated" class="me-3" mat-raised-button color="primary" (click)="onDelete(sub, subChange)" [innerHTML]="translationService.getTranslation('button_delete')"></button>
                <button *ngIf="!subChange.outdated" class="mr-3" mat-raised-button (click)="onEdit(sub, subChange)">{{translationService.getTranslation('button_edit')}}</button>
              </mat-action-row>
              <ng-template matExpansionPanelContent *ngIf="subChange.state != 3">
                <div class="row" *ngIf="subChange.address && subChange.person && subChange.person.lastname">
                  <div class="col-6" [innerHTML]="translationService.getTranslation('address_delivery')"></div>
                  <div class="col-6">
                      <app-address-detail [address]="subChange.address"
                                          [person]="subChange.person"></app-address-detail>
                  </div>
                </div>
                <div class="row" *ngIf="subChange.reasonMemo">
                  <div class="col-6" [innerHTML]="translationService.getTranslation('complaint_reasonMemo')"></div>
                  <div class="col-6">
                    {{subChange.reasonMemo}}
                  </div>
                </div>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>
</div>
