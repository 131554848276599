import {Component, HostListener, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {TranslationService} from '../../../../core/services/translation.service';
import {BaseDataService} from '../../../../core/services/base-data.service';
import {BaseData} from '../../../../data/models/base-data.model';
import {AddressSearchService} from '../../../../core/services/address-search.service';
import {AddressSearch} from '../../../../data/models/address-search.model';
import {LocaleService} from '../../../../core/services/locale.service';
import {ConfigService} from 'src/app/core/services/config.service';
import {AddressCode} from "../../../../data/models/address-code.model";
import {ClientType} from "../../../../data/models/client-type.model";
import {DateAdapter} from "@angular/material/core";
import {CustomValidators} from "../../../../core/validators";

@Component({
  selector: 'app-hup-subscription-address[controlGroup]',
  templateUrl: './hup-subscription-address.component.html',
  styleUrls: ['./hup-subscription-address.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HupSubscriptionAddressComponent implements OnInit {
  @Input() showActivateCheckbox = false;
  @Input() showSalutation = true;
  @Input() showAddressExt1 = true;
  @Input() showTitle = true;
  @Input() askForBirthday = false;
  @Input() isBirthdayRequired = false;
  @Input() askForEmail = false;
  @Input() showValidFrom = false;
  @Input() askForPhone = false;
  @Input() isPhoneRequired = false;
  @Input() activateCheckboxText: 'activateCheckboxText';
  @Input() controlGroup: FormGroup;
  @Input() minDate: Date = new Date();
  @Input() isMobile = false;
  @Input() askForCountry = true;
  @Input() askForPerson = true;

  salutations: AddressCode[] = [];
  clientTypes: ClientType[] = [];
  countries: BaseData[] = [];
  titles: BaseData[] = [];

  zipcodes: string[] = [];
  streetNames: string[] = [];
  cities: string[] = [];
  defaultCountry: string;
  addressPartSequence: string ='0';

  earliestBirthdateForSubscription: Date;
  minimalAllowedUserAge = 18;

  addressSearch: AddressSearch = new AddressSearch(null, null, null, null, null, null);

  constructor(public translationService: TranslationService,
              private baseDataService: BaseDataService,
              private addressSearchService: AddressSearchService,
              private localeService: LocaleService,
              public configService: ConfigService,
              private dateAdapter: DateAdapter<Date>,
) {
  this.dateAdapter.getFirstDayOfWeek = () => 1;
}

  ngOnInit(): void {
    this.setPhoneFieldCustomValidator();

    this.countries = this.baseDataService.getBaseData('countrycodes');
    const locale = this.localeService.getDefaultCountry();
    const country = locale.split('_');

    this.countries?.sort((a, b) => {
      const textA = a.description.toUpperCase();
      const textB = b.description.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    this.countries?.forEach(_country => {
      if (_country.key === country[0].toUpperCase()) {
        this.defaultCountry = _country.key;
        if (!this.controlGroup.get('address.country').getRawValue() || this.controlGroup.get('address.country').getRawValue() === '') {
          this.controlGroup.get('address.country').setValue(_country, {emitEvent: true});
        }
      }
    })
    this.configService.loadConfig('address.addresspart.sequence')
      .subscribe(config => {
        if (config){
          this.addressPartSequence = config.value;
        }
      });

    if (this.defaultCountry === 'NL') {
      if (!this.showActivateCheckbox) {
        this.controlGroup.get('person').get('initials').setValidators(Validators.required);
        this.controlGroup.get('person').get('firstName').clearValidators();
      }
    } else {
      //this.controlGroup.get('person').get('firstName').setValidators(Validators.required);
      this.controlGroup.get('address.street').valueChanges.subscribe(value => {
        this.addressSearch.streetName = value;
        this.unsetCityAndStreet();
        this.searchStreet();
      });

      this.controlGroup.get('address.city').valueChanges.subscribe(value => {
        this.addressSearch.city = value;
        this.unsetCityAndStreet();
        this.searchCity();
      });
      if(!this.showActivateCheckbox && this.showSalutation) {
        this.onSalutationChange('')
      }
      this.controlGroup.get('person.salutation').valueChanges.subscribe(value => {
        this.onSalutationChange(value.key);
      });
    }

    this.salutations = this.baseDataService.getBaseData('addresscodes');
    this.clientTypes = this.baseDataService.getBaseData('clienttypes');
    this.titles = this.baseDataService.getBaseData('titles');

    this.controlGroup.get('address.zipcode').addValidators([
      Validators.pattern(/[^A-Za-z0-9]*/g),
      Validators.maxLength(10)
    ]);
    this.controlGroup.get('address.houseNo').addValidators([
      Validators.pattern(/[^A-Za-z0-9\-]*/g),
      Validators.maxLength(10)
    ]);

    console.debug('Register Address Listener');
    this.controlGroup.get('address.street').valueChanges.subscribe(value => {
      this.addressSearch.streetName = value;
      this.searchStreet();
      console.debug('Street Changed');
    });

    this.controlGroup.get('address.zipcode').valueChanges.subscribe(value => {
      if (value || value === '') {
        value = value.toUpperCase();
        this.addressSearch.zipcode = value;
        this.unsetCityAndStreet();
        if (this.defaultCountry === 'NL') {
          this.streetNames = [];
          this.cities = [];
          if (this.addressSearch.houseNo !== null && this.addressSearch.houseNo !== '' && this.addressSearch.zipcode !== null && this.addressSearch.zipcode !== '') {
            this.searchZipCode();
          }
        } else {
          this.searchZipCode();
        }
        console.debug('Zipcode Changed');
      }
    });

    this.controlGroup.get('address.city').valueChanges.subscribe(value => {
      this.addressSearch.city = value;
      this.unsetCityAndStreet();
      this.searchCity();
      console.debug('City Changed');
    });

    this.controlGroup.get('address.houseNo').valueChanges.subscribe(value => {
      this.addressSearch.houseNo = value;
      this.unsetCityAndStreet();
      if (this.defaultCountry === 'NL') {
        this.streetNames = [];
        this.cities = [];
      }
      this.searchAddress();
      console.debug('Houseno Changed');
    });
    console.debug('Registered Address Listener');
    /*
        this.controlGroup.get('address.district').valueChanges.subscribe(value => {
          this.addressSearch.district = value;
          this.searchAddress();
        });
     */

    this.controlGroup.get('showAddress')?.valueChanges.subscribe(value => {
      if (this.defaultCountry === 'NL' && value === true) {
        // validatoren an
        this.controlGroup.get('person').get('initials').setValidators(Validators.required);
      } else if (value === false) {
        // validatoren aus
        this.controlGroup.get('person').get('initials').clearValidators();
        this.controlGroup.reset();
      }
    });

    this.configService.loadConfig('subscription.minimal-user-age').subscribe(config => {
      if (config) {
        this.minimalAllowedUserAge = parseInt(config.value);
      }
      this.handleEarliestBirthdayForSubscription(this.minimalAllowedUserAge);
    });
  }

  @HostListener('focusout', ['$event'])
  onInputBlur(event: FocusEvent) {
    const target = event.target as HTMLInputElement;
    if (target && target.tagName === 'INPUT') {
      this.trimAllFormControls(this.controlGroup);
    }
  }

  trimAllFormControls(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control) {
        if (control instanceof FormGroup) {
          this.trimAllFormControls(control);
        } else {
          if (typeof control.value === 'string') {
            control.setValue(control.value.trim(), { emitEvent: false });
          }
        }
      }
    });
  }

  private unsetCityAndStreet() {
    if (this.defaultCountry === 'NL') {
      this.controlGroup.get('address.city')?.setValue(null, {emitEvent: false});
      this.controlGroup.get('address.street')?.setValue(null, {emitEvent: false});
    }
    if (this.controlGroup.get('address.street')?.disabled) {
      this.addressSearch.streetName = null;
    }
    if (this.controlGroup.get('address.city')?.disabled) {
      this.addressSearch.city = null;
    }
  }

  searchStreet(): void {
    if (this.defaultCountry !== 'NL') {
      this.addressSearchService.searchStreet(this.addressSearch).subscribe(searchResult => {
        this.streetNames = [];
        this.addressSearch.zipcode = this.addressSearch.zipcode.trim();
        if (searchResult) {
          searchResult.forEach(result => {
            this.streetNames.push(result.streetName);
          });
        }
        this.streetNames = [...new Set(this.streetNames)];
        this.enableDisableFields();
        this.controlGroup.get('address.district').setValue(searchResult[0]?.district);
      });
    }
  }

  searchZipCode(): void {
    this.addressSearchService.searchZipcode(this.addressSearch).subscribe(searchResult => {
      this.zipcodes = [];
      this.addressSearch.zipcode = this.addressSearch?.zipcode?.trim();
      if (searchResult) {
        searchResult.forEach(result => {
          this.zipcodes.push(result.zipcode);
        });
      }
      this.zipcodes = [...new Set(this.zipcodes)];
      this.enableDisableFields();
    });
  }

  searchCity(): void {
    if (this.defaultCountry !== 'NL') {
      this.addressSearchService.searchCity(this.addressSearch).subscribe(searchResult => {
        this.cities = [];
        this.addressSearch.zipcode = this.addressSearch.zipcode.trim();
        if (searchResult) {
          searchResult.forEach(result => {
            this.cities.push(result.city);
          });
        }
        this.cities = [...new Set(this.cities)];
        this.enableDisableFields();
      });
    }
  }

  searchAddress(): void {
    if (this.addressSearch.city || this.addressSearch.streetName || this.addressSearch.zipcode) {
      if (this.defaultCountry === 'NL') {
        this.addressSearch.streetName = null;
        this.addressSearch.city = null;
        this.addressSearch.district = null;
        this.addressSearchService.searchAddress(this.addressSearch).subscribe(searchResult => {
          this.streetNames = [];
          this.cities = [];
          this.zipcodes = [];
          this.addressSearch.zipcode = this.addressSearch.zipcode.trim();
          if (searchResult) {
            searchResult.forEach(result => {
              this.streetNames.push(result.streetName);
              this.cities.push(result.city);
              this.zipcodes.push(result.zipcode);
            });
          }
          this.streetNames = [...new Set(this.streetNames)];
          this.cities = [...new Set(this.cities)];
          this.zipcodes = [...new Set(this.zipcodes)];
          this.enableDisableFields();
        });
      }
    }
  }


  private enableDisableFields(searchStreet: boolean = false, searchCity: boolean = false) {
    if (this.defaultCountry === 'NL') {
      if (this.streetNames.length == 1) {

        this.controlGroup.get('address.street')?.setValue(this.streetNames[0], {emitEvent: false});
        this.controlGroup.get('address.street')?.disable({emitEvent: false});
      } else if (!searchStreet) {

        this.controlGroup.get('address.street')?.enable({emitEvent: false});
        this.controlGroup.get('address.street')?.setValue(null, {emitEvent: false});
      }
      if (this.cities.length == 1) {

        this.controlGroup.get('address.city')?.setValue(this.cities[0], {emitEvent: false});
        this.controlGroup.get('address.city')?.disable({emitEvent: false});
      } else if(!searchCity) {

        this.controlGroup.get('address.city')?.enable({emitEvent: false});
        this.controlGroup.get('address.city')?.setValue(null, {emitEvent: false});
      }
    }
  }

  compareBaseData(op1: BaseData, op2: any) {
    return op1.key === op2 || op1.key === op2?.key;
  }

  printForm(): void {
    console.log(this.controlGroup);
  }

  replaceInvalidCharactersFromZipcode() {
    let value = this.controlGroup.get('address.zipcode').value.toUpperCase();
    if (value.length > 10) value = value.slice(0, 10);
    this.controlGroup.get('address.zipcode').setValue(value.replace(/[^A-Za-z0-9]*/g, ''), {emitEvent: false});
  }

  watchInitials(event) {
    const initialsControl = this.controlGroup.get('person.initials');
    let value = initialsControl.value.toUpperCase();
    if (event.keyCode == 8) {
      value = value.substring(0, value.length - 1);
    }
    value = value.replaceAll(/[^a-z]/ig, '').split('').join('.');
    value += value.length ? '.' : '';
    initialsControl.setValue(value, {emitEvent: false});
  }

  /**
   * Fügt einen custom validator zu den Telefonnummernfeldern hinzu, sodass nur keine Eingabe & ganze Tel (Vorwahl & Nummer) erlaubt sind, keine halben telefonnummern. Greift nur falls das Telefonnummernfeld optional ist, da bei Pflichtfeld sowieso alles ausgefüllt werden muss
   */
  setPhoneFieldCustomValidator() {

      if (this.askForPhone && !this.isPhoneRequired) {

        this.controlGroup.get('userData.phone').setValidators(CustomValidators.checkCompletePhonenumber(
          this.translationService,
          this.controlGroup.get('userData.phone'),
          this.controlGroup.get('userData.phonecode')
        ));
        this.controlGroup.get('userData.phonecode').setValidators(CustomValidators.checkCompletePhonenumber(
          this.translationService,
          this.controlGroup.get('userData.phone'),
          this.controlGroup.get('userData.phonecode')
        ));
      }

  }

  protected readonly document = typeof document === 'object' ? document : null;

  onSalutationChange(salutation: string) {
    if(this.controlGroup.get('showAddress')?.value === true) {
      this.controlGroup.get('person.firstName').addValidators(Validators.required);
    }
    if(salutation && salutation != '') {
      this.salutations.forEach(ac => {
        if(ac.key == salutation) {
          this.clientTypes.forEach( ct => {
            if (ct.defaultAddressCodeId && ct.defaultAddressCodeId === ac.key) {
              if (ct.justOneNameField) {
                this.controlGroup.get('person.firstName').clearValidators();
              } else {
                this.controlGroup.get('person.firstName').addValidators(Validators.required);
              }
            }
          });
        }
      });
    }
    this.controlGroup.get('person.firstName').updateValueAndValidity();
  }

  private handleEarliestBirthdayForSubscription(minAge: number) {
    const today = new Date();
    this.earliestBirthdateForSubscription = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
  }
}
