import {TranslationService} from "../services/translation.service";

export interface DateParse {
  dateInput: string;
}
export class CustomDateFormat {
  constructor(private translationService: TranslationService) {
  }
  private _parse: DateParse = {
    dateInput: this.translationService.getTranslation('dateFormat')
  };

  set parse(parse: DateParse) {
    this._parse = Object.assign({}, this._parse, parse);
  }

  get parse(): DateParse {
    return this._parse;
  }

  public updateDateFormat(parse: DateParse) {
    this.parse = parse;
  }
}
