<ng-container [formGroup]="controlGroup">
  <div *ngIf="showActivateCheckbox" class="col-12">
    <mat-checkbox formControlName="showAddress"><div [innerHTML]="translationService.getTranslation(activateCheckboxText)"></div></mat-checkbox>
  </div>

  <ng-container *ngIf="showValidFrom">
    <mat-form-field class="col-12 ps-2" (click)="picker.open()" formGroupName="validDate">
      <mat-label [innerHTML]="translationService.getTranslation('startdate')"></mat-label>
      <input matInput [min]="minDate" [matDatepicker]="picker" readonly required formControlName="validFrom">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker [touchUi]="isMobile" #picker></mat-datepicker>
      <mat-error
        *ngIf="controlGroup.get('validDate').hasError('message')">{{controlGroup.get('validDate').getError('message')}}</mat-error>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="!showActivateCheckbox || controlGroup.get('showAddress').value">
    <ng-container *ngIf="defaultCountry === 'NL'; else notNL">
    <ng-container formGroupName="person">
      <mat-form-field *ngIf="showSalutation" class="col-12 col-md-4 ps-2">
        <mat-label [innerHTML]="translationService.getTranslation('address_salutation')"></mat-label>
        <mat-select formControlName="salutation" required [compareWith]="compareBaseData">
          <ng-container *ngFor="let salutation of salutations">
            <mat-option *ngIf="salutation.useInFE" [value]="salutation">{{salutation.description}}</mat-option>
          </ng-container>
        </mat-select>
        <mat-error *ngIf="controlGroup.get('person.salutation').hasError('message')">{{controlGroup.get('person.salutation').getError('message')}}</mat-error>
      </mat-form-field>
      <mat-form-field class="col-12 col-md-4 ps-2">
        <mat-label>{{translationService.getTranslation('address_initials')}}</mat-label>
        <input maxLength="50"
          id="initialInputForm"
          matInput
          formControlName="initials"
          mask="S.S.S.S.S"
          type="text"
          [dropSpecialCharacters]="false"
          [validation]="false" #addressInitials>
        <mat-hint *ngIf="addressInitials === document?.activeElement && addressInitials.value.length >= 1" align="end">{{addressInitials.value.length}} / {{addressInitials.maxLength}}</mat-hint>
        <mat-error *ngIf="controlGroup.get('person.initials').hasError('message')">{{controlGroup.get('person.initials').getError('message')}}</mat-error>
      </mat-form-field>
      <mat-form-field class="col-12 col-md-4 ps-2">
        <mat-label [innerHTML]="translationService.getTranslation('address_surName')"></mat-label>
        <input maxLength="50" matInput formControlName="middlename" #middleName>
        <mat-hint *ngIf="middleName === document?.activeElement && middleName.value.length >= 1" align="end">{{middleName.value.length}} / {{middleName.maxLength}}</mat-hint>
        <mat-error *ngIf="controlGroup.get('person.middlename').hasError('message')">{{controlGroup.get('person.middlename').getError('message')}}</mat-error>
      </mat-form-field>
      <mat-form-field class="col-12 col-md-8 ps-2" >
        <mat-label [innerHTML]="translationService.getTranslation('address_lastname')"></mat-label>
        <input maxLength="50" matInput formControlName="lastName" required #lastName>
        <mat-hint *ngIf="lastName === document?.activeElement && lastName.value.length >= 1" align="end">{{lastName.value.length}} / {{lastName.maxLength}}</mat-hint>
        <mat-error *ngIf="controlGroup.get('person.lastName').hasError('message')">{{controlGroup.get('person.lastName').getError('message')}}</mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container  formGroupName="address">
      <mat-form-field *ngIf="askForCountry" class="col-12 col-md-4 ps-2">
        <mat-label [innerHTML]="translationService.getTranslation('address_country')"></mat-label>
        <mat-select formControlName="country" required id="nlCountry">
          <mat-option *ngFor="let country of countries" [value]="country">{{country.description}}</mat-option>
        </mat-select>
        <mat-error *ngIf="controlGroup.get('address.country').hasError('message')">{{controlGroup.get('address.country').getError('message')}}</mat-error>
      </mat-form-field>

      <mat-form-field class="col-12 col-md-6 ps-2">
        <mat-label [innerHTML]="translationService.getTranslation('address_postcode')"></mat-label>
        <input matInput formControlName="zipcode" (keyup)="replaceInvalidCharactersFromZipcode()" required>
        <mat-error *ngIf="controlGroup.get('address.zipcode').hasError('message')">{{controlGroup.get('address.zipcode').getError('message')}}</mat-error>
      </mat-form-field>
      <mat-form-field class="col-12 col-md-6 ps-2">
        <mat-label [innerHTML]="translationService.getTranslation('address_houseno')"></mat-label>
        <input maxLength="50" matInput formControlName="houseNo" required #houseNumber>
        <mat-hint *ngIf="houseNumber === document?.activeElement && houseNumber.value.length >= 1" align="end">{{houseNumber.value.length}} / {{houseNumber.maxLength}}</mat-hint>
        <mat-error *ngIf="controlGroup.get('address.houseNo').hasError('message')">{{controlGroup.get('address.houseNo').getError('message')}}</mat-error>
      </mat-form-field>
        <mat-form-field class="col-12 ps-2">
          <mat-label [innerHTML]="translationService.getTranslation('address_street')"></mat-label>
          <input matInput formControlName="street" required [matAutocomplete]="matStreetNames">
          <mat-autocomplete #matStreetNames="matAutocomplete">
            <mat-option *ngFor="let street of streetNames" [value]="street">
              {{street}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="controlGroup.get('address.street').hasError('message')">{{controlGroup.get('address.street').getError('message')}}</mat-error>
        </mat-form-field>
        <mat-form-field class="col-12 ps-2">
          <mat-label [innerHTML]="translationService.getTranslation('address_city')"></mat-label>
          <input matInput formControlName="city" required [matAutocomplete]="matCities">
          <mat-autocomplete #matCities="matAutocomplete">
            <mat-option *ngFor="let city of cities" [value]="city">
              {{city}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="controlGroup.get('address.city').hasError('message')">{{controlGroup.get('address.city').getError('message')}}</mat-error>
        </mat-form-field>

      <mat-form-field *ngIf="showAddressExt1" class="col-12 ps-2">
        <mat-label [innerHTML]="translationService.getTranslation('address_extraline1')"></mat-label>
        <input maxLength="50" matInput formControlName="extraLine" #addressExtra>
        <mat-hint *ngIf="addressExtra === document?.activeElement && addressExtra.value.length >= 1" align="end">{{addressExtra.value.length}} / {{addressExtra.maxLength}}</mat-hint>
        <mat-error *ngIf="controlGroup.get('address.extraLine').hasError('message')">{{controlGroup.get('address.extraLine').getError('message')}}</mat-error>
      </mat-form-field>
    </ng-container>
    </ng-container>
    <ng-template #notNL>
      <ng-container *ngIf="askForPerson" formGroupName="person">
        <mat-form-field *ngIf="showSalutation" class="col-12 col-md-6 ps-2">
          <mat-label [innerHTML]="translationService.getTranslation('address_salutation')"></mat-label>
          <mat-select formControlName="salutation" required [compareWith]="compareBaseData" data-cy="select-salutation">
            <ng-container *ngFor="let salutation of salutations">
              <mat-option *ngIf="salutation.useInFE" [value]="salutation" data-cy="option-salutation">{{salutation.description}}</mat-option>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="controlGroup.get('person.salutation').hasError('message')">{{controlGroup.get('person.salutation').getError('message')}}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="showTitle" class="col-12 col-md-6 ps-2">
          <mat-label [innerHTML]="translationService.getTranslation('address_title')"></mat-label>
          <mat-select formControlName="title" [compareWith]="compareBaseData">
            <mat-option value=""></mat-option>
            <ng-container *ngFor="let title of titles" >
              <mat-option *ngIf="title.useInFE" [value]="title">{{title.description}}</mat-option>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="controlGroup.get('person.title').hasError('message')">{{controlGroup.get('person.title').getError('message')}}</mat-error>
        </mat-form-field>

        <mat-form-field class="col-12 col-md-6 ps-2">
          <mat-label [innerHTML]="translationService.getTranslation('address_firstname')"></mat-label>
          <input maxLength="50" matInput formControlName="firstName" #firstName data-cy="input-first-name">
          <mat-hint *ngIf="firstName === document?.activeElement && firstName.value.length >= 1" align="end">{{firstName.value.length}} / {{firstName.maxLength}}</mat-hint>
          <mat-error *ngIf="controlGroup.get('person.firstName').hasError('message')">{{controlGroup.get('person.firstName').getError('message')}}</mat-error>
        </mat-form-field>
        <mat-form-field class="col-12 col-md-6 ps-2" >
          <mat-label [innerHTML]="translationService.getTranslation('address_lastname')"></mat-label>
          <input maxLength="50" matInput formControlName="lastName" required #lastName data-cy="input-last-name">
          <mat-hint *ngIf="lastName === document?.activeElement && lastName.value.length >= 1" align="end">{{lastName.value.length}} / {{lastName.maxLength}}</mat-hint>
          <mat-error *ngIf="controlGroup.get('person.lastName').hasError('message')">{{controlGroup.get('person.lastName').getError('message')}}</mat-error>
        </mat-form-field>
      </ng-container>

      <ng-container formGroupName="address">
        <ng-container *ngIf="addressPartSequence==='1';else addressPartSequence0">
          <app-hup-subscription-address-specifics [controlGroup]="controlGroup" [streetNames]="streetNames"
                                                  (searchAddressEmit)="this.searchStreet()"></app-hup-subscription-address-specifics>
          <mat-form-field class="col-12 col-md-6 ps-2">
            <mat-label [innerHTML]="translationService.getTranslation('address_postcode')"></mat-label>
            <input matInput formControlName="zipcode" (keyup)="replaceInvalidCharactersFromZipcode()" required
                   [matAutocomplete]="matZipCodes" (focusin)="this.searchZipCode()">
            <mat-autocomplete #matZipCodes="matAutocomplete">
              <mat-option *ngFor="let zipcode of zipcodes" [value]="zipcode">
                {{ zipcode }}
              </mat-option>
            </mat-autocomplete>
            <mat-error
              *ngIf="controlGroup.get('address.zipcode').hasError('message')">{{ controlGroup.get('address.zipcode').getError('message') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-12 col-md-6 ps-2">
            <mat-label [innerHTML]="translationService.getTranslation('address_city')"></mat-label>
            <input matInput formControlName="city" required [matAutocomplete]="matCities" (focusin)="this.searchCity()">
            <mat-autocomplete #matCities="matAutocomplete">
              <mat-option *ngFor="let city of cities" [value]="city">
                {{ city }}
              </mat-option>
            </mat-autocomplete>
            <mat-error
              *ngIf="controlGroup.get('address.city').hasError('message')">{{ controlGroup.get('address.city').getError('message') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="askForCountry" class="col-12 ps-2">
            <mat-label [innerHTML]="translationService.getTranslation('address_country')"></mat-label>
            <mat-select formControlName="country" required [compareWith]="compareBaseData" id="country">
              <mat-option *ngFor="let country of countries" [value]="country">{{ country.description }}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="controlGroup.get('address.country').hasError('message')">{{ controlGroup.get('address.country').getError('message') }}
            </mat-error>
          </mat-form-field>

        </ng-container>

        <ng-template #addressPartSequence0>
          <mat-form-field *ngIf="askForCountry" class="col-12 ps-2">
            <mat-label [innerHTML]="translationService.getTranslation('address_country')"></mat-label>
            <mat-select formControlName="country" required [compareWith]="compareBaseData" id="country0">
              <mat-option *ngFor="let country of countries" [value]="country">{{ country.description }}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="controlGroup.get('address.country').hasError('message')">{{ controlGroup.get('address.country').getError('message') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-12 col-md-6 ps-2">
            <mat-label [innerHTML]="translationService.getTranslation('address_postcode')"></mat-label>
            <input matInput formControlName="zipcode" (keyup)="replaceInvalidCharactersFromZipcode(); this.searchZipCode()" required
                   [matAutocomplete]="matZipCodes" (focusin)="this.searchZipCode()" >
            <mat-autocomplete #matZipCodes="matAutocomplete">
              <mat-option *ngFor="let zipcode of zipcodes" [value]="zipcode">
                {{ zipcode }}
              </mat-option>
            </mat-autocomplete>
            <mat-error
              *ngIf="controlGroup.get('address.zipcode').hasError('message')">{{ controlGroup.get('address.zipcode').getError('message') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-12 col-md-6 ps-2">
            <mat-label [innerHTML]="translationService.getTranslation('address_city')"></mat-label>
            <input matInput formControlName="city" required [matAutocomplete]="matCities" (focusin)="this.searchCity()">
            <mat-autocomplete #matCities="matAutocomplete">
              <mat-option *ngFor="let city of cities" [value]="city">
                {{ city }}
              </mat-option>
            </mat-autocomplete>
            <mat-error
              *ngIf="controlGroup.get('address.city').hasError('message')">{{ controlGroup.get('address.city').getError('message') }}
            </mat-error>
          </mat-form-field>
          <app-hup-subscription-address-specifics [controlGroup]="controlGroup" [streetNames]="streetNames"
                                                  (searchAddressEmit)="this.searchStreet()"></app-hup-subscription-address-specifics>
        </ng-template>

        <mat-form-field class="col-12 ps-2">
          <mat-label [innerHTML]="translationService.getTranslation('address_extraline1')"></mat-label>
          <input maxlength="50" matInput formControlName="extraLine" #addressExtra>
          <mat-hint *ngIf="addressExtra === document?.activeElement && addressExtra.value.length >= 1" align="end">{{addressExtra.value.length}} / {{addressExtra.maxLength}}</mat-hint>
          <mat-error *ngIf="controlGroup.get('address.extraLine').hasError('message')">{{controlGroup.get('address.extraLine').getError('message')}}</mat-error>
        </mat-form-field>
      </ng-container>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="(!showActivateCheckbox || controlGroup.get('showAddress').value) && (askForBirthday || askForEmail ||askForPhone)" formGroupName="userData">
      <mat-form-field *ngIf="askForBirthday" class="col-12 ps-2">
        <mat-label [innerHTML]="translationService.getTranslation('address_birthday')"></mat-label>
        <input matInput [max]="earliestBirthdateForSubscription" [matDatepicker]="birthDaypicker" formControlName="birthDay"
               [datePickerFormat]="translationService.getTranslation('dateFormat')">
              <mat-datepicker-toggle matSuffix [for]="birthDaypicker"></mat-datepicker-toggle>
        <mat-datepicker [touchUi]="isMobile" #birthDaypicker startView="multi-year"></mat-datepicker>
        <mat-error
          *ngIf="controlGroup.get('userData').hasError('message')">{{ controlGroup.get('userData').getError('message') }}
        </mat-error>
        <mat-error *ngIf="controlGroup.get('userData.birthDay').hasError('matDatepickerMax')"
                   [innerHTML]="translationService.getTranslation('subscription.error.minimal-user-age').replace('%s', minimalAllowedUserAge.toString())">
        </mat-error>
      </mat-form-field>

    <mat-form-field *ngIf="askForPhone" class="col-12 col-md-3 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_phonecode')"></mat-label>
      <input matInput formControlName="phonecode">
      <mat-error
        *ngIf="controlGroup.get('userData.phonecode').hasError('message')">{{controlGroup.get('userData.phonecode').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="askForPhone" class="col-12 col-md-9 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_telephone')"></mat-label>
      <input matInput formControlName="phone">
      <mat-error
        *ngIf="controlGroup.get('userData.phone').hasError('message')">{{controlGroup.get('userData.phone').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="askForEmail" class="col-12 ps-2 d-none" >
      <mat-label [innerHTML]="translationService.getTranslation('register_email')"></mat-label>
      <input matInput formControlName="email" readonly>
      <mat-error
        *ngIf="controlGroup.get('userData').hasError('message')">{{controlGroup.get('userData').getError('message')}}</mat-error>
    </mat-form-field>
  </ng-container>
</ng-container>

<!--button (click)="printForm()">Print Form</button-->
